.mq-card__title {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: var(--gl-spacing-scale-3);

  .mq-card-title__label {
    width: fit-content;
    display: inline-flex;
    align-items: center;
    gap: var(--gl-spacing-scale-3);
    font-size: var(--mq-font-size-scale-3);
    color: currentColor;

    &.sm {
      font-size: var(--mq-font-size-scale-3);
      font-weight: 700;
    }

    &.md {
      font-size: var(--mq-font-size-scale-6);
      font-weight: unset;
    }
  }

  .mq-card-title__description {
    font-weight: 700;
    font-size: var(--mq-font-size-scale-3);
    font-style: initial;
    color: var(--color-neutral-text-med);
  }

  &.indigo {
    color: var(--color-brand-indigo);
  }
}