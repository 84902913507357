.mq-modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: var(--gl-spacing-scale-3);
  gap: var(--gl-spacing-scale-3);
  border-bottom: 1px solid var(--color-info-disabled-stroke);

  &__title {
    display: flex;
    gap: var(--gl-spacing-scale-3);
    align-items: flex-start;

    &-icon {
      font-size: var(--mq-font-size-scale-5);
      font-weight: bold;
      color: var(--color-brand-indigo);
    }
  }

  &__close {
    min-width: 2rem;
    min-height: 2rem;
    font-size: var(--mq-font-size-scale-3);
    padding: 0;
    border: none;
    color: var(--color-brand-blue);
    cursor: pointer;
    background-color: transparent;

    &:focus,
    &:active,
    &:hover {
      color: var(--color-brand-indigo);
    }
  }
}