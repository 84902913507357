.mq-loader-dots {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  &.sm {
    .mq-loader-dots__dot {
      height: 8px;
      width: 8px;
      gap: var(--gl-spacing-scale-5);
    }
  }

  &.md {
    .mq-loader-dots__dot {
      height: 10px;
      width: 10px;
      border: 1px solid var(--color-marketing-purple-med);
      gap: 30px;
    }
  }

  &__dot {
    border-radius: var(--mq-border-radius-circle);
    animation: bubbling 2s infinite;

    &:nth-child(2n) {
      animation-delay: 0.2s;
    }

    &:nth-child(3n) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes bubbling {
  0% {
    transform: scale(0.5);
    background: var(--color-brand-indigo);
  }
  50% {
    transform: scale(1);
    background: var(--color-neutral-surface);
  }
  100% {
    transform: scale(0.5);
    background: var(--color-brand-indigo);
  }
}