.switch-user {
  width: 100%;
  display: flex;
  flex-direction: column;

  .switch-user__candidates {
    height: 100%;
    max-height: 350px;
    overflow-y: auto;
  }
}
