.app-sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--admin-panel-background);
  border-right: 1px solid var(--admin-panel-border);

  .app-sidebar__nav {
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow-y: clip;
    overflow-x: hidden;

    &:hover {
      overflow-y: auto;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--admin-panel-scrollbar);
    }

    .mq-sidebar__item {
      &.active {
        .mq-sidebar__link {
          background-color: var(--admin-panel-active-link-background);
        }

        .mq-sidebar__list {
          background-color: var(--admin-panel-rollover);
        }
      }

      .mq-sidebar__link {
        color: var(--admin-panel-link-text);


        &.active {
          color: var(--admin-panel-active-link-text);
          background-color: var(--admin-panel-active-link-background);
        }

        &:hover {
          &:not(.active) {
            color: var(--admin-panel-link-text-hover);
            background: var(--admin-panel-rollover);

            .mq-sidebar__link {
              background-color: var(--admin-panel-rollover);
            }
          }
        }
      }
    }

    .mq-sidebar__heading {
      color: var(--admin-panel-title);
    }

    .mq-sidebar__list-toggle {
      &:hover {
        .mq-sidebar__link {
          background-color: var(--admin-panel-rollover);
        }
      }

      .mq-sidebar__item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .mq-sidebar__link {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:hover {
          .mq-sidebar__link {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      .mq-sidebar__list-badge {
        color: var(--admin-panel-program-counter);
        background-color: rgba(0, 0, 0, 0.1);
        border-color: rgba(0, 0, 0, 0.2);

        &.drafts {
          background-color: var(--admin-panel-background);
          border-style: dashed;
          border-color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .app-sidebar__toggle {
    width: 100%;
    height: 25px;
    position: relative;

    .app-sidebar__toggle-button {
      width: 25px;
      position: absolute;
      top: 50px;
      right: 0;
      transform: translateX(50%);
      z-index: 2;
      background-color: var(--admin-panel-toggle-icon-background);
      color: var(--admin-panel-toggle-icon);
      box-shadow: var(--mq-shadow-lg);

      &:focus {
        background-color: var(--admin-panel-toggle-icon-background);
        color: var(--admin-panel-toggle-icon);
        box-shadow: var(--mq-shadow-lg);
      }

      &:hover {
        background-color: var(--admin-panel-toggle-icon-background-hover);
        color: var(--admin-panel-toggle-icon-hover);
      }
    }
  }
}