.mq-nav-dropdown {
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;

  &.secondary {
    height: 100%;

    .mq-nav-dropdown__menu {
      min-width: 100%;
    }
  }

  &.show {
    .mq-nav-dropdown__menu {
      height: auto;
      display: block;
    }
  }
}