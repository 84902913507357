$arrow-size: 12;
$arrow-margin: 6;

.mq-tooltip-wrapper {
  z-index: 999;

  .mq-tooltip {
    display: flex;
    width: max-content;
    font-size: var(--mq-font-size-scale-2);
    max-height: 50vh;
    position: relative;
    padding: var(--gl-spacing-scale-3);
    text-align: left;
    background-color: var(--color-brand-indigo);
    border-radius: var(--mq-border-radius-sm);
    color: var(--color-neutral-text-light);

    * {
      color: var(--color-neutral-text-light);
    }

    &.sm {
      max-width: 200px;
    }

    &.md {
      max-width: 300px;
    }

    &.lg {
      max-width: 600px;
    }

    .mq-tooltip-inner {
      width: 100%;
      height: inherit;
      overflow-y: auto;
    }

    .mq-tooltip__arrow {
      position: absolute;
      width: #{$arrow-size}px;
      height: #{$arrow-size}px;
      transform: rotate(45deg);
      background-color: var(--color-brand-indigo);
    }

    &[data-placement="top"] {
      margin: var(--gl-spacing-scale-5) 0;

      .mq-tooltip__arrow {
        left: -#{$arrow-margin}px;
        bottom: -#{$arrow-margin}px;
      }
    }

    &[data-placement="bottom"] {
      margin: var(--gl-spacing-scale-5) 0;

      .mq-tooltip__arrow {
        left: -#{$arrow-margin}px;
        top: -#{$arrow-margin}px;
      }
    }

    &[data-placement="left"] {
      margin: 0 var(--gl-spacing-scale-5);

      .mq-tooltip__arrow {
        right: -#{$arrow-margin}px;
        top: -#{$arrow-margin}px;
      }
    }

    &[data-placement="right"] {
      margin: 0 var(--gl-spacing-scale-5);

      .mq-tooltip__arrow {
        left: -#{$arrow-margin}px;
        top: -#{$arrow-margin}px;
      }
    }
  }
}
