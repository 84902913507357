.mq-form-radio-card {
  max-width: 290px;
  min-width: 200px;
  padding: var(--gl-spacing-scale-5) var(--gl-spacing-scale-3);
  background-color: var(--color-neutral-surface);
  border: 2px solid transparent;
  border-radius: var(--mq-border-radius-md);
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: var(--mq-shadow-md);

  .mq-form-radio-card__header {
    max-width: 80%;
    min-width: 140px;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    background-color: var(--color-brand-indigo);
    color: var(--color-neutral-text-light);
    border-radius: var(--mq-border-radius-md);
    padding: var(--gl-spacing-scale-2) var(--gl-spacing-scale-3);
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: inherit;
    line-height: var(--mq-line-height-sm);
  }

  .mq-form-radio-card__content {
    width: 100%;
    height: 100%;
    padding: var(--gl-spacing-scale-3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gl-spacing-scale-3);
    word-break: break-word;
    text-align: center;
  }

  .mq-form-radio-card__icon {
    display: flex;
    justify-content: center;
    margin: 0.5em 0;
    font-size: var(--mq-font-size-scale-8);
    text-align: center;
    color: var(--color-brand-indigo);
  }

  .mq-form-radio-card__toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-color: var(--color-neutral-bkg);
    border: 0.25rem solid var(--color-neutral-surface);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  &:hover {
    &:not(.checked),
    &:not(.disabled) {
      box-shadow: var(--mq-shadow-lg);
      transform: scale(1.1);
      border-color: var(--color-brand-indigo);
    }

    .mq-form-radio-card__toggle {
      background-color: var(--color-neutral-text-dim);
    }
  }

  &.checked {
    border-color: var(--color-brand-indigo);

    .mq-form-radio-card__header {
      background-color: var(--color-brand-indigo);
    }

    .mq-form-radio-card__icon {
      color: var(--color-brand-indigo);
    }

    .mq-form-radio-card__toggle {
      background-color: var(--color-brand-indigo);

      &:before {
        content: '';
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 100%;
        background-color: var(--color-neutral-surface);
      }
    }
  }

  &.disabled {
    opacity: 0.6;
    border: none;

    .mq-form-radio-card__toggle {
      background-color: var(--color-neutral-bkg);
    }
  }

  .mq-form-radio-card__input {
    display: none;
  }
}