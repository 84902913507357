.mq-card-header {
  min-height: 48px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: var(--gl-spacing-scale-3);
  padding: var(--gl-spacing-scale-3);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom: 1px solid var(--color-neutral-stroke);

  &.alt {
    background-color: var(--color-neutral-bkg);
  }

  &.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;

    .mq-card__subtitle {
      width: auto;
    }
  }

  &.space-between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }
}