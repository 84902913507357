.mq-modal__footer {
  display: flex;
  justify-content: space-between;
  border-bottom-right-radius: var(--mq-border-radius-md);
  border-bottom-left-radius: var(--mq-border-radius-md);
  padding: var(--gl-spacing-scale-3);
  border-top: 1px solid var(--color-info-disabled-stroke);
  gap: var(--gl-spacing-scale-3);

  &.end {
    justify-content: end;
  }

  &.start {
    justify-content: start;
  }

  &.space-between {
    justify-content: space-between;
  }
}