.mq-navbar__collapse {
  height: 100%;

  &.expanded {
    width: fit-content;
    height: auto;
    max-height: 100vh;
    max-width: 100%;
    top: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    background-color: var(--color-neutral-surface);
    border-radius: 0 0 var(--mq-border-radius-sm) var(--mq-border-radius-sm);
    border: 1px solid var(--color-neutral-stroke);
    box-shadow: var(--mq-shadow-sm);
    display: none;

    &.show {
      display: unset;
    }

    .mq-nav {
      gap: 0;
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .mq-link {
      width: 100%;
      padding: var(--gl-spacing-scale-5);
      border-bottom: 1px solid rgb(0, 0, 0, 0.1);

      &:hover {
        background-color: var(--color-neutral-bkg);
        color: var(--color-brand-blue);
      }
    }

    .mq-nav-dropdown {
      padding: 0;
    }

    .mq-dropdown__toggle {
      width: 100%;
      border-bottom: 1px solid rgb(0, 0, 0, 0.1);

      .mq-dropdown__toggle-button {
        margin-right: var(--gl-spacing-scale-5);
      }

      &:hover {
        background-color: var(--color-neutral-bkg);
        color: var(--color-brand-blue);
      }

      .mq-link {
        width: 100%;
      }

      &:hover {
        .mq-link {
          color: var(--color-brand-blue);
        }
      }
    }

    .mq-nav-dropdown__menu {
      width: 100%;
      left: 0;
      min-width: unset;
      display: block;
      position: relative;
      border-radius: 0;
      box-shadow: none;
      border: 0;

      .mq-dropdown__item {
        padding-left: var(--gl-spacing-scale-10);
        border-bottom: 1px solid rgb(0, 0, 0, 0.1);
      }
    }
  }

  &.navbar-collapse {
    flex-basis: unset;
    flex-grow: 1;
    align-items: unset;
  }
}
