.mq-card-body {
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  background-color: var(--color-neutral-surface);

  &.alt {
    background-color: var(--color-neutral-bkg);
  }

  &:not(.compact) {
    padding: var(--gl-spacing-scale-5);
  }

  &.sm {
    gap: var(--gl-spacing-scale-3);
  }
}