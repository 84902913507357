.mq-loader-skeleton {
  width: 100%;

  &.square {
    border-radius: var(--mq-border-radius-sm);
  }

  &.circle {
    border-radius: var(--mq-border-radius-circle);
  }

  &.indigo {
    background-color: var(--color-brand-indigo);
  }

  &.indigo-pale {
    background-color: var(--color-brand-indigo-pale);
  }

  &.text {
    background-color: var(--color-neutral-text);
  }

  &.text-pale {
    background-color: var(--color-neutral-text-pale);
  }

  &.background {
    background-color: var(--color-neutral-bkg);
  }

  &:not(.static) {
    animation-timing-function: ease-in-out;

    &.slow {
      animation-duration: 3s;
    }

    &.fast {
      animation-duration: 1s;
    }

    &.sm {
      animation-name: smallRangePulse;
      animation-iteration-count: infinite;
    }

    &.md {
      animation-name: mediumRangePulse;
      animation-iteration-count: infinite;
    }

    &.lg {
      animation-name: largeRangePulse;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes smallRangePulse {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes mediumRangePulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes largeRangePulse {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}