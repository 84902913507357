.mq-svg-icon {
  color: currentColor;

  svg {
    display: inline-block;
    height: 1em;
    fill: currentColor;
    vertical-align: -0.125em;
    overflow: visible;
    box-sizing: content-box;
  }

  &.mq-svg-size-1x {
    font-size: 1em;
  }

  &.mq-svg-size-2x {
    font-size: 2em;
  }

  &.mq-svg-size-lg {
    font-size: 1.25em;
    vertical-align: -0.2em
  }

  &.mq-svg-size-3x {
    font-size: 3em;
  }

  &.mq-svg-size-4x {
    font-size: 4em;
  }

  &.light {
    color: var(--color-neutral-surface);
  }

  &.dark {
    color: var(--color-neutral-text);
  }

  &.primary {
    color: var(--color-brand-indigo);
  }

  &.warning {
    color: var(--color-info-warning-text);
  }

  &.danger {
    color: var(--color-info-danger-text);
  }

  &.warning-light {
    color: var(--color-info-warning);
  }

  &.success {
    color: var(--color-info-success-text);
  }

  &.success-light {
    color: var(--color-info-success);
  }

  &.indigo {
    color: var(--color-brand-indigo);

    .fa-primary {
      opacity: 1;
      fill: var(--color-brand-indigo);
    }

    .fa-secondary {
      opacity: 1;
      fill: var(--color-brand-indigo-light);
    }
  }

  &.indigo-reverse {
    color: var(--color-brand-indigo-light);

    .fa-primary {
      opacity: 1;
      fill: var(--color-brand-indigo-light);
    }

    .fa-secondary {
      opacity: 1;
      fill: var(--color-brand-indigo);
    }
  }

  &.purple {
    color: var(--color-marketing-purple);

    .fa-primary {
      opacity: 1;
      fill: var(--color-marketing-purple);
    }

    .fa-secondary {
      opacity: 1;
      fill: var(--color-brand-indigo-light);
    }
  }

  &.purple-med {
    color: var(--color-marketing-purple-med);

    .fa-primary {
      opacity: 1;
      fill: var(--color-marketing-purple-med);
    }

    .fa-secondary {
      opacity: 1;
      fill: var(--color-brand-indigo-light);
    }
  }

  &.blue {
    &:not(:has(.fa-primary, .fa-secondary)) {
      color: var(--color-brand-blue);
    }

    &:has(.fa-primary, .fa-secondary) {
      .fa-primary {
        fill: var(--color-brand-blue);
        opacity: 1;
      }

      .fa-secondary {
        fill: var(--color-brand-indigo);
        opacity: 1;
      }
    }
  }

  &.blue-reverse {
    .fa-primary {
      fill: var(--color-brand-indigo);
      opacity: 1;
    }

    .fa-secondary {
      fill: var(--color-info-blue);
      opacity: 1;
    }
  }
}

.mq-svg-icon + * {
  margin-right: 0.5em;
}