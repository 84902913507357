.mq-nav-accordion-item {
  overflow: hidden;

  .mq-nav-accordion-item_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gl-spacing-scale-3);
    padding: var(--gl-spacing-scale-3);
    cursor: pointer;

    .mq-nav-accordion-item_label {
      font-size: var(--mq-font-size-scale-2);
      flex: 10 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .mq-nav-accordion-item_badge {
      display: flex;
      align-items: center;
    }
  }

  .mq-nav-accordion-item_content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }

  &.open {
    .mq-nav-accordion-item_content {
      max-height: 100vh;
    }
  }
}