.mq-modal__title {
  width: 100%;
  text-align: start;
  margin-bottom: 0;
  margin-top: 0;
  font-size: var(--mq-font-size-scale-5);
  font-weight: normal;
  color: var(--color-brand-indigo) !important;

  &.bold {
    font-weight: bold;
  }
}