.mq-caption {
  color: var(--color-neutral-text-med);
  font-size: var(--mq-font-size-scale-3);
  margin: 0;

  &.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.mixed {
    * {
      display: contents;
      max-width: 100%;
    }
  }

  &.bold {
    font-weight: 500;
  }

  &.center {
    text-align: center;
  }

  &.italic {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }
}