.empty-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gl-spacing-scale-5);
  align-items: flex-start;
  overflow: hidden;

  .empty-list__content {
    max-width: 100%;
    min-width: 200px;
  }
}