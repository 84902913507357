.mq-dropdown__toggle {
  width: max-content;
  display: flex;
  align-items: center;
  height: 100%;

  .mq-link {
    color: var(--color-neutral-text);
    height: 100%;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mq-dropdown__toggle-button {
    margin-right: var(--gl-spacing-scale-3);
  }

  &:hover {
    .mq-link  {
      color: var(--color-brand-blue);

      .end-icon,
      .start-icon {
        color: inherit;
      }
    }
  }
}