.mq-title {
  color: currentColor;
  font-weight: 400;
  margin: 0;

  &.bold {
    font-weight: 700;
  }

  &.mixed {
    * {
      display: contents;
      max-width: 100%;
    }
  }

  &.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.center {
    text-align: center;
  }

  &.italic {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }

  &.h1 {
    font-size: var(--mq-font-size-scale-8);
  }

  &.h2 {
    font-size: var(--mq-font-size-scale-6);
  }

  &.h3 {
    font-size: var(--mq-font-size-scale-5);
  }

  &.h4 {
    font-size: var(--mq-font-size-scale-5);
  }

  &.h5 {
    font-size: var(--mq-font-size-scale-3);
  }

  &.h6 {
    font-size: var(--mq-font-size-scale-2);
  }
}
