.mq-link {
  line-height: var(--mq-line-height-sm);

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: var(--color-info-disabled-text);
  }

  &:hover,
  &:active,
  &:focus {
    &:not(:disabled),
    &:not(.disabled) {
      border-color: var(--color-brand-indigo);
    }
  }


  .start-icon,
  .end-icon {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  &.nowrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--gl-spacing-scale-3);
  }

  &.inline {
    display: inline;
  }

  &.bolder {
    font-weight: bold;
  }

  &.underline {
    text-decoration: underline;
  }

  &.compact {
    padding: 0;
  }

  &.truncate {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--gl-spacing-scale-3);

    .mq-nav__link-text {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:not(.neutral) {
    max-width: 100%;
    width: fit-content;
    height: max-content;
    min-height: 2.4rem;
    max-height: 2.5rem;
    display: inline-flex;
    align-items: center;
    border-radius: var(--mq-border-radius-sm);
    border: 1px solid transparent;
    text-align: center;
    justify-content: center;
    padding: var(--gl-spacing-scale-3) var(--gl-spacing-scale-5);
    background-color: transparent;
    font-weight: 700;
    line-height: var(--mq-line-height-sm);
    position: relative;
    transition: all ease-in-out .3s;
    cursor: pointer;
    gap: var(--gl-spacing-scale-3);

    &.primary {
      &:not(:disabled),
      &:not(.disabled) {
        background-color: var(--color-brand-blue);
        border-color: var(--color-brand-blue);
        color: var(--color-neutral-surface);
      }

      &:hover,
      &:active,
      &:focus {
        &:not(:disabled),
        &:not(.disabled) {
          background-color: var(--color-brand-indigo);
          border-color: var(--color-brand-indigo);
        }
      }
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      color: var(--color-info-disabled-text) !important;
      background-color: var(--color-info-disabled) !important;
      border-color: var(--color-info-disabled-stroke) !important;
      box-shadow: none;
      outline: none;
    }
  }
}