.mq-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  outline: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  transition-property: all;
  transition-timing-function: ease-in-out;
  z-index: 4;

  @keyframes show {
    0% {
      opacity: 0.5;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  @keyframes hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }


  &.visible {
    .mq-modal-dialog {
      animation-duration: inherit;
      animation-name: show;
    }
  }

  &:not(.visible) {
    .mq-modal-dialog {
      opacity: 0;
      transform: scale(0);
      animation-duration: inherit;
      animation-name: hide;
    }
  }

  &.centered {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &.expanded {
    .mq-modal-dialog {
      height: 90%;
      width: 100%;
    }
  }

  &.blurBackground {
    backdrop-filter: blur(5px);
  }

  .mq-modal-dialog {
    width: 100%;
    position: relative;
    margin: auto;
    z-index: 1;
    transition: inherit;

    &.scrollable {
      display: flex;
      max-height: 100%;

      .mq-modal {
        max-height: 100%;
        overflow: hidden;

        .mq-modal-body {
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
    }

    &.sm {
      max-width: 400px;
    }

    &.md {
      max-width: 600px;
    }

    &.lg {
      max-width: 900px;
    }

    &.xl {
      max-width: 1100px;
    }

    .mq-modal {
      border-radius: 1rem;
      background-color: var(--color-neutral-surface);
      display: flex;
      flex-direction: column;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      outline: 0;
      pointer-events: auto;
      position: relative;
      width: 100%;
      box-shadow: var(--mq-shadow-lg);
    }
  }
}