.mq-paragraph {
  color: currentColor;
  font-size: var(--mq-font-size-scale-3);
  margin: 0;
  font-weight: normal;

  &.nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.mixed {
    * {
      display: contents;
      max-width: 100%;
    }
  }

  &.bold {
    font-weight: 700;
  }

  &.center {
    text-align: center;
  }

  &.italic {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }
}