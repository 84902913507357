$sm-size: 16px;
$md-size: 45px;
$lg-size: 90px;

.mq-loader-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gl-spacing-scale-9);

  &.compact {
    padding: 0;
    width: max-content;
  }

  .mq-loader-spinner__wrapper {
    position: relative;
    display: block;

    &.sm {
      width: $sm-size;
      height: $sm-size;
    }

    &.md {
      width: $md-size;
      height: $md-size;
    }

    &.lg {
      width: $lg-size;
      height: $lg-size;
    }

    &.primary {
      animation: primary-animations 2s ease-in-out infinite;

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        height: inherit;
        width: inherit;
        border: solid var(--color-neutral-stroke);
        border-radius: 50%;
      }

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        height: inherit;
        width: inherit;
        content: '';
        background: url("./assets/images/spinner.svg") no-repeat;
      }

      &.sm {
        &::before {
          border-width: 1px;
        }
      }

      &.md {
        &::before {
          border-width: 2px;
        }
      }

      &.lg {
        &::before {
          border-width: 3px;
        }
      }
    }

    &.secondary {
      animation: secondary-animations 2s ease-in-out infinite;
      border-radius: 50%;
      border: 2px solid transparent;
      border-top-color: var(--color-neutral-text-med);
    }
  }
}

@keyframes primary-animations {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes secondary-animations {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}