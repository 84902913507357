.mq-dropdown__item {
  min-height: 56px;
  padding: var(--gl-spacing-scale-5);
  gap: var(--gl-spacing-scale-5);
  background: var(--color-neutral-surface);
  cursor: pointer;
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  }

  &:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &:hover {
    background-color: var(--color-neutral-bkg);
    color: var(--color-brand-blue);
  }

  .mq-dropdown__item-label{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}